.container-datepicker {
  border: 0;
  z-index: 999999 !important;
}

.container-input-calendar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99999999 !important;
}

.menu-item-active {
  padding: 8px;
  border-radius: 8px;
  background-color: #032a47;
}

.menu-item-active svg path {
  fill: white;
}

.vehicle-options-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
